.sliders {
  position: relative;
  height: 550px;
  &__button-container {
    display: flex;
    justify-content: center;
    gap: 13px;
    padding-bottom: 33px;
    margin-top: 20px;
    @supports (-webkit-touch-callout: none) {
      gap: 10px;
    }

    .slider-button {
      &__inactive {
        position: relative;
        border: 0;
        // cursor: pointer;
        background-color: transparent;
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          background-color: rgba(29, 51, 59, 0.15);
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }

      &__active {
        position: relative;
        background-color: transparent;
        border: 0;
        // cursor: pointer;
        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          background-color: var(--rich-black-blue-color);
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
  }

  &__close-button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: transparent;
      border: 0;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  &__background-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width: 100%;
    border-radius: 42px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--rich-black-blue-color);
    text-align: center;
    margin: 15px;
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #494949;
    margin: 10px 35px;
  }

  &__prev {
    position: absolute;
    top: 45%;
    border: 0;
    background-color: transparent;
    left: -20px;
    background-color: var(--white-bg-color);
    padding: 15px 20px;
    border-radius: 100%;
    box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.34);
    cursor: pointer;
  }
  &__next {
    position: absolute;
    top: 45%;
    border: 0;
    background-color: transparent;
    right: -20px;
    transform: rotate(180deg);
    background-color: var(--white-bg-color);
    padding: 15px 20px;
    border-radius: 100%;
    box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.34);
    cursor: pointer;
  }
}
