.forgot-password-email-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media (min-width: 300px) {
    background-image: url('../../assets/images/webp/email-mobile.webp');
    .logo-position {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  @media (min-width: 768px) {
    background-image: url('../../assets/images/webp/email-desktop.webp');
    .logo-position {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
