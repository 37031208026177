.manage-subscriptions {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media (min-width: 300px) {
    background-image: url('../../assets/images/webp/verification-mobile.webp');
    .logo-position {
      padding-top: 50px;
      padding-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    background-image: url('../../assets/images/webp/verification-desktop.webp');
    .logo-position {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  &__title {
    color: var(--dark-gray-color);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto;
    width: 150px;
  }
  &__buttons {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: var(--dark-gray-color);
    background-color: transparent;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    svg {
      margin-left: 10px;
      margin-top: 3px;
      height: 14px;
      path {
        stroke: var(--dark-gray-color);
      }
    }
  }
}
#manage_subscription_modal {
  position: relative;
  .iframe-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; 
  }
  .iframe {
    position: relative;
    z-index: 1;
  }
}
