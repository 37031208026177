.button {
  border: 0;
  background-color: var(--yellow-bg-color);
  border-radius: 34px;

  font-weight: 700;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--white-bg-color);

  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  cursor: pointer;

  // &:disabled {
  //   background-color: var(--rich-black-blue-color);
  // }
}
