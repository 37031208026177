.subscriptions-bg {
  background-color: var(--subscription-background);
}

.subscription-logo {
  position: sticky;
  bottom: 50%;
  @media (min-width: 300px) {
    margin-top: 50px;
  }

  @media (min-width: 768px) {
    margin: 0;
  }
}

.subscription-main-title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: var(--rich-black-blue-color);
  @media (min-width: 300px) {
    padding-top: 10px;
  }
  @media (min-width: 768px) {
    padding-top: 50px;
  }
}

.subscription-main-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 0;
  color: var(--rich-black-blue-color);
  padding-top: 6px;
  padding-bottom: 20px;
}

.what-you-get-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: var(--rich-black-blue-color);
  margin: 0;
  padding-top: 30px;
}

.what-you-get-grid {
  padding: 0 20px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border: none;
  border-radius: 37px;
  height: 145px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .remove-space {
    width: 1px;
    margin-left: -6px;
    padding-right: 13px;
  }

  div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 8;
    }
  }

  &__title {
    line-height: 21px;
    color: var(--white-bg-color);
    margin: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
  }
}

.subscription-choose-trial-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--rich-black-blue-color);
  margin: 0;
}

.subscription-cancel-any-time-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--description-color);
  margin: 0;
}

.subscription-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.promo-code-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--rich-black-blue-color);
  margin-bottom: 0px;
}

.terms-and-privacy {
  text-align: center;

  @media (min-width: 300px) {
    padding: 0px 15px 36px 15px;
  }

  @media (min-width: 768px) {
    width: 445px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  &__account {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  &__account-button {
    font-weight: 600;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
  }

  &__cancel {
    color: var(--rich-black-blue-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &__buttons {
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--rich-black-blue-color);
    padding: 10px 0;

    a {
      color: var(--rich-black-blue-color);
    }
  }

  &__description {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: var(--rich-black-blue-color);
  }
}

#subscription_modal {
  position: relative;
  .iframe-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  .iframe {
    position: relative;
    z-index: 1;
  }
}
.subscription-promo-code {
  position: relative;
  &__answer {
    width: 24px;
    height: 24px;
    background: #808080;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: 0;
    border: 0;
    cursor: pointer;
    svg {
      position: absolute;
    }
  }
}

#question_promo-code_modal {
  .promo-code-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: var(--rich-black-blue-color);
      margin: 0;
    }

    &__description {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--rich-black-blue-color);
    }

    button {
      width: 100%;
    }
  }
}
