.subscription-card {
  border-radius: 30px;
  width: 100%;
  cursor: pointer;
  background: #fcf6f0;

  transition: all 100ms;

  &__type {
    font-size: 13px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: var(--white-bg-color);
    font-weight: 700;

    padding: 5px;
    margin: 0;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__block {
    text-align: center;
  }

  &__name {
    white-space: pre-line;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    color: var(--rich-black-blue-color);
    margin: 0;
    text-transform: capitalize;
    padding-top: 10px;
  }

  &__price {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    text-align: center;

    margin: 0;
    padding: 3px;
    // padding: 15px 0;
    padding-top: 20px;
  }

  &__duration {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;

    text-align: center;
    color: var(--rich-black-blue-color);
    margin: 0;

    padding-bottom: 10px;
  }

  &__note {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
  }
}
