:root {
  --yellow-bg-color: #ffb231;
  --white-bg-color: #ffffff;
  --rich-black-blue-color: #1d333b;
  --dark-yellow-color: #cf812d;
  --dark-blue-color: #a7c6d0;
  --light-gray-color: #9d9d9d;
  --dark-gray-color: #0b2931;
  --light-bg-color: #f4eee7;
  --light-description-grey-color: #6f6f6f;
  --error-color: #ff7b7b;
  --description-color: #494949;
  --subscription-background: #f5eee6;
  --custom-input-color: #fcf6f0;
}

body {
  margin: 0;
  font-family: 'Poppins';
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  //   'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

button {
  font-family: 'Poppins';
}
// .fade-enter {
//   opacity: 0;
//   transform: translate(0, 25px);
//   z-index: 1;
// }
// .fade-enter.fade-enter-active {
//   opacity: 1;
//   transform: translate(0, 0);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
// .fade-exit {
//   opacity: 1;
//   transform: translate(0, 0);
// }
// .fade-exit.fade-exit-active {
//   opacity: 0;
//   transform: translate(0, 30px);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
