.rectangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;

  border-radius: 32px;

  @media (min-width: 300px) {
    padding: 27px 24px 24px 24px;
    margin: 0 15px;
  }

  @media (min-width: 768px) {
    padding: 27px 24px 24px 24px;
    width: 445px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.rectangle-transparent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;

  border-radius: 32px;

  @media (min-width: 300px) {
    padding: 20px 10px;
    margin: 10px;
  }

  @media (min-width: 768px) {
    padding: 10px 24px;
    width: 445px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
