.one-time-password-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media (min-width: 300px) {
    background-image: url('../../assets/images/webp/first-name-mobile.webp');
    .logo-position {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  @media (min-width: 768px) {
    background-image: url('../../assets/images/webp/first-name-desktop.webp');
    .logo-position {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
