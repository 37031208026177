// .main {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (min-width: 300px) {
//     justify-content: flex-end;
//   }

//   @media (min-width: 768px) {
//     justify-content: center;
//   }
// }

.main-title {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  color: var(--dark-gray-color);
  margin: 0;
}

.secondary-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: var(--rich-black-blue-color);
  margin: 0;
}

.error-message {
  text-align: center;
  color: var(--error-color);
  margin: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 32px;
  margin-bottom: -25px;
  padding-top: 8px;
}

.log-in {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  font-weight: normal;
  color: var(--light-gray-color);
  margin: 0;
}

.description-thin {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  color: var(--dark-gray-color);
  margin: 0;
}

.info {
  text-align: center;
  color: var(--dark-yellow-color);
  margin-top: 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-without-text {
    @media (min-width: 300px) {
      width: 43px;
      height: 56px;
    }

    @media (min-width: 768px) {
      width: 146px;
      height: 100px;
    }
  }
}

.grid {
  @media (min-width: 300px) {
    height: 100vh;
  }

  @media (min-width: 768px) {
    display: grid;
    width: 100%;
    height: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
}

.subscription-page-grid {
  display: grid;
  width: 100%;

  @media (min-width: 300px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 768px) {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
}

.full-screen {
  // background-color: #282c34;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // overflow: hidden;

  // @media (min-width: 300px) {
  //   justify-content: flex-end;
  // }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
  }
}

iframe {
  border: none;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

// Hide chargebee close icon
.cb-hp__close {
  display: none;
}

.forgot-password {
  border: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 2px;

  color: var(--light-description-grey-color);
  cursor: pointer;
  text-transform: uppercase;
}

.fixed-block {
  @media (min-width: 300px) {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 10px);
    width: 100%;
  }

  @media (min-width: 768px) {
    position: relative;
  }
}
