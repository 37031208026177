.s-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 2;
}

.s-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.s-modal.exit {
  opacity: 0;
}

.s-modal-content {
  margin: 0 35px;
  width: 500px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  position: relative;
}

.s-modal.enter-done .s-modal-content {
  transform: translateY(0);
}

.s-modal.exit .s-modal-content {
  transform: translateY(-200px);
}

.s-modal-header,
.s-modal-footer {
  padding: 10px;
}

.s-modal-title {
  margin: 0;
}

// .s-modal-body {
//   border-top: 1px solid #eee;
//   border-bottom: 1px solid #eee;
// }

.s-modal-close {
  background-color: transparent;
  border: none;
  position: absolute;
  right: -15px;
  top: -55px;
  cursor: pointer;
}

.rounded-25 {
  border-radius: 25px;
}

.rounded-45 {
  border-radius: 45px;
}

.rounded-10 {
  border-radius: 10px;
}
