.done-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media (min-width: 300px) {
    background-image: url('../../assets/images/webp/welcome-mobile.webp');
    .logo-position {
      padding-top: 100px;
      padding-bottom: 80px;
    }
  }

  @media (min-width: 768px) {
    background-image: url('../../assets/images/webp/welcome-desktop.webp');
    .logo-position {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .one-month-trial {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: var(--light-description-grey-color);
    text-decoration: underline;
  }
}
