.input-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &__input {
    padding: 16px;
    border-radius: 100px;
    font-size: 18px;
    color: var(--rich-black-blue-color);
    text-align: center;
    font-weight: 500;
    line-height: 32px;

    border: 1px solid var(--white-bg-color);

    &::placeholder {
      color: var(--rich-black-blue-color);
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  &__icon {
    position: absolute;
    border: none;
    background-color: transparent;
    right: 10px;
    top: 19px;
  }

  .error {
    border: 1px solid red;
  }

  &__error-message {
    position: absolute;
    bottom: -45px;
    left: 20px;
    color: red;
    font-size: 13px;
  }

  &__promo-code-placeholder {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: var(--rich-black-blue-color);

    &::placeholder {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: var(--light-gray-color);
    }
  }
}
