.email-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;

  @media (min-width: 300px) {
    background-image: url('../../assets/images/webp/email-mobile.webp');
    .logo-position {
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    background-image: url('../../assets/images/webp/email-desktop.webp');
    .logo-position {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .log-in {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    color: var(--light-description-grey-color);
  }

  .button {
    margin-top: 10px;
  }

  .login-button {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    border: none;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    color: var(--light-description-grey-color);
    cursor: pointer;
  }
}
